<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.nickname" placeholder="请输入用户昵称" clearable style="width:160px;" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.cellphone" placeholder="请输入手机号" clearable style="width:160px;" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.authIdCardStatus" placeholder="请选择实名状态" clearable style="width:160px;">
            <el-option v-for="(value, key) in authIdCardStatus" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="queryTime.createTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="举报开始时间"
            end-placeholder="举报结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="change"
          />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.type" placeholder="请选择举报类型" clearable style="width:160px;">
            <el-option v-for="(value, key) in type" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
          <el-button type="primary" @click="batchNotice">批量通知</el-button>
          <el-button type="primary" @click="batch">批量拉黑</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="nickname"
        label="用户昵称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号码"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="headUrl"
        label="用户头像"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <el-image style="width:50px;height:50px;border-radius:50%;" :src="row.headUrl" alt="" :preview-src-list="[row.headUrl]" />
        </template>
      </el-table-column>
      <el-table-column
        prop="authIdCardStatus"
        label="实名认证"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ authIdCardStatus[row.authIdCardStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="举报时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="type"
        label="举报类型"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ type[row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="详细描述"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-row>

            <el-button type="primary" size="small" @click="detail(row.id)">详情</el-button>

            <el-button type="danger" size="small" @click="noticeUser(row.id)">通知</el-button>
          </el-row>
          <el-row style="margin-top:10px;">

            <el-button v-if="row.status === 1" type="danger" size="small" @click="disable(row.id)">禁用</el-button>
            <el-button v-else type="success" size="small" @click="enable(row.id)">启用</el-button>

            <el-button type="danger" size="small" @click="notice(row.id)">拉黑</el-button>

          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <el-dialog
      title="项目详情"
      :visible.sync="detailShow"
      width="600px"
    >
      <template v-if="detailShow">
        <el-descriptions class="margin-top" :column="1" border>
          <el-descriptions-item label="用户名称">{{ userDetail.nickname }}</el-descriptions-item>
          <el-descriptions-item label="用户头像">
            <el-image style="width:100px;height:100px;" :src="userDetail.headUrl" alt="" :preview-src-list="[userDetail.headUrl]" />
          </el-descriptions-item>
          <el-descriptions-item label="举报类型">{{ type[userDetail.type] }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{ userDetail.cellphone }}</el-descriptions-item>
          <el-descriptions-item label="详情信息">{{ userDetail.content }}</el-descriptions-item>
          <el-descriptions-item label="详情图片">
            <el-image style="width:100px;height:100px;" :src="userDetail.imageUrl" alt="" :preview-src-list="[userDetail.imageUrl]" />
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </el-dialog>
    <el-dialog
      title="通知内容"
      :visible.sync="noticeShow"
      width="600px"
    >
      <el-form v-if="noticeShow" label-width="auto">
        <el-form-item label="内容">
          <el-input v-model="content" type="textarea" />
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="end">
        <el-button @click="noticeShow = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/reportUser'
export default {
  mixins: [list],
  data() {
    return {
      api,
      type: {
        1: '资料透露联系方式',
        2: '虚假资料',
        3: '垃圾营销广告',
        4: '欺诈嫌疑',
        5: '其他'
      },
      authIdCardStatus: {
        0: '未认证',
        1: '认证申请中',
        2: '认证通过',
        3: '认证失败'
      },
      audit: {
        0: '待审核',
        1: '审核通过',
        2: '审核不通过'
      },
      createTime: [],
      arrivalCreateTime: [],
      examineShow: false,
      examineId: '',
      auditStatus: '',
      detailShow: false,
      userDetail: {},
      ids: [],
      noticeShow: false,
      content: '',
      noticeId: '',
      // 是不是批量通知
      isbatch: false
    }
  },
  methods: {
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      this.ids = val
    },
    batch() {
      if (this.ids.length) {
        var newIds = []
        this.ids.forEach(val => {
          newIds.push(val.id)
        })
        this.api.notice(newIds).then(() => {
          this.$message({
            message: '拉黑成功',
            type: 'success'
          })
          this.getList()
        })
      } else {
        this.$message({
          message: '请勾选要拉黑的用户',
          type: 'error'
        })
      }
    },
    batchNotice() {
      if (this.ids.length) {
        this.noticeShow = true
        this.isbatch = true
      } else {
        this.$message({
          message: '请勾选要通知的用户',
          type: 'error'
        })
      }
    },
    change(val) {
      if (val) {
        this.query.startTime = val[0]
        this.query.endTime = val[1]
      } else {
        this.query.startTime = ''
        this.query.endTime = ''
      }
    },
    enable(id) {
      this.api.enable(id).then(() => {
        this.$message({
          message: '启用成功',
          type: 'success'
        })
        this.getList()
      })
    },
    disable(id) {
      this.api.disable(id).then(() => {
        this.$message({
          message: '禁用成功',
          type: 'success'
        })
        this.getList()
      })
    },
    detail(id) {
      this.detailShow = true
      this.api.reportDetail(id).then(res => {
        this.userDetail = res.data
      })
    },
    notice(id) {
      this.api.notice([id]).then(() => {
        this.$message({
          message: '拉黑成功',
          type: 'success'
        })
        this.getList()
      })
    },
    noticeUser(id) {
      this.noticeShow = true
      this.noticeId = id
      // 不是批量通知
      this.isbatch = false
    },
    onSubmit() {
      if (!this.isbatch) {
        this.api.noticeUser({ ids: [this.noticeId], content: this.content }).then(() => {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.content = ''
          this.noticeShow = false
          this.getList()
        })
      } else {
        var newIds = []
        this.ids.forEach(val => {
          newIds.push(val.id)
        })
        this.api.noticeUser({ ids: newIds, content: this.content }).then(() => {
          this.$message({
            message: '通知成功',
            type: 'success'
          })
          this.content = ''
          this.noticeShow = false
          this.getList()
        })
      }
    }
  }
}
</script>
