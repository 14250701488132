import request from '@/utils/axios'

/** 获取用户举报列表 */
export function getList(params) {
  return request({
    url: '/report',
    method: 'get',
    params
  })
}

/** 通知用户 */
export function noticeUser(data) {
  return request({
    url: '/report/notice',
    method: 'post',
    data
  })
}

/** 获取用户举报信息详情 */
export function reportDetail(id) {
  return request({
    url: `/report/detail?id=${id}`,
    method: 'get'
  })
}

/** 禁用 */
export function disable(id) {
  return request({
    url: `/report/disable?id=${id}`,
    method: 'get'
  })
}

/** 启用 */
export function enable(id) {
  return request({
    url: `/report/enable?id=${id}`,
    method: 'get'
  })
}

/** 拉黑 */
export function notice(data) {
  return request({
    url: `/report/batch`,
    method: 'post',
    data
  })
}
